import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, debounceTime, distinctUntilChanged, interval, map, of, pipe, switchMap, tap } from 'rxjs';
import moment from 'moment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PracticeStore } from './practices.store';
import { UserStore } from './users.store';
import { CompanyStore } from './clients.store';
import { InvoiceStore } from './invoices.store';
import { PromoStore } from './promos.store';

export interface CommonState {
  dashboardDetails: [];
  filterOrgId: any;
}
const initialCommonState: CommonState = {
  dashboardDetails: [],
  filterOrgId: null
};
export const CommonStore = signalStore(
  withState<CommonState>(initialCommonState),
  withComputed((store) => {
    const userStore = inject(UserStore);
    const companyStore = inject(CompanyStore);
    const invoiceStore = inject(InvoiceStore);
    return {
      userData: computed(()=>{
        let users = userStore.userData();
        if(users){
          return userStore.userData().map((user: any) => {
            const organization = store.dashboardDetails().find((org: any) => org.users.includes(user._id));
            
            const organizationname = organization ? organization["organizationname"] : '';
           //   const userRole = organization ? organization.mainUserId == user._id ? 'Super Admin' :  : 'Staff';
            let mainUserId = organization ? organization["mainUserId"] : '';
            let orgAdmins = organization && organization["admins"] ? organization["admins"] : [];
            let userRole = "Staff";
            let userId = user._id;
            
            if(user._id == mainUserId){
              userRole = "Super Admin"
            }else if(orgAdmins){
              let adminUser = orgAdmins.find((admin: any) => admin == userId);
              if (adminUser)
                userRole = "Admin"
              else
                userRole = "Staff"
            }
            let fullName = user.firstname + ' ' + user.lastname;
           // userRole = user._id == mainUserId ? "Super Admin" : orgAdmins.includes(userId) ? "Admin" : "Staff";
            return {
              ...user,
              organizationname,
              userRole,
              fullName
            };
          });
        }
        
      }),
      compData: computed(()=>{
        let companies = companyStore.compData();
        if(companies){
          return companies.map((company: any) => {
            const practice = store.dashboardDetails().filter((org: any) => org._id == company.organizationId).map((practice: any)=>{
            return {
                "organizationname":practice.organizationname,
                "isBilling": practice.isBilling,
                "isCancelled": practice.isCancelled,
                "end_date": practice.end_date
            }
        });
            let addedById = company.addedBy ? company.addedBy : company.userId;
            const user = userStore.userData().find((user: any) => user._id == addedById);
           //const practice = prac.filter((practice: any) => company.organizationId == practice._id)
        const mainUsername = user ? (user.firstname + ' ' + user.lastname) : '';
        const mainUseremail = user ? user.email : '';
            
            return {
              ...company,
              mainUsername,
              mainUseremail,
              practice
            };
          });
        }
        
      }),
      delCompData: computed(()=>{
        let companies = companyStore.delCompData();
        if(companies){
          return companies.map((company: any) => {
            const practice = store.dashboardDetails().filter((org: any) => org._id == company.organizationId).map((practice: any)=>{
              
            return {
                "organizationname":practice.organizationname,
                "isBilling": practice.isBilling,
                "isCancelled": practice.isCancelled,
                "end_date": practice.end_date
            }
        });
        let addedById = company.deletedBy ? company.deletedBy : company.userId;
            const user = userStore.userData().find((user: any) => user._id == addedById);
           //const practice = prac.filter((practice: any) => company.organizationId == practice._id)
        const mainUsername = user ? (user.firstname + ' ' + user.lastname) : '';
        const mainUseremail = user ? user.email : '';
        const organizationname = practice.length > 0 ? practice[0].organizationname : '';    
            return {
              ...company,
              mainUsername,
              mainUseremail,
              organizationname,
              practice
            };
          });
        }
        
      }),
      invData: computed(()=>{
        let invoices = invoiceStore.invData();
        if(invoices){

          return invoiceStore.invData().map((invoice: any) => {
            const organization = store.dashboardDetails().find((org: any) => org._id == invoice.organizationId);
            const organizationname = organization ? organization["organizationname"] : '';
            return {
              ...invoice,
              organizationname
            };
          })
        }
        
      })
    }
  }),
  withMethods(({...store}, pracStore = inject(PracticeStore), userStore = inject(UserStore), companyStore = inject(CompanyStore), invoiceStore = inject(InvoiceStore), promoStore = inject(PromoStore)) => ({
    
    setDashboardLogic(data: any) {
      const getAllPractices = pracStore.orgData();
      const selectedUsers = userStore.userData();
      const selectedCompanies = companyStore.compData();
      const selectedInvoices = invoiceStore.invData();
      const selectedPromos = promoStore.promoData();
      const selectedDeletedCompanies = companyStore.delCompData();
      
      if(getAllPractices?.length > 0 && selectedUsers?.length > 0 && selectedCompanies?.length > 0 && selectedInvoices?.length > 0 && selectedDeletedCompanies?.length > 0) {
        
        const getUpdatePractices =  getAllPractices.map((practice: any, index: any) => {
        
          const user = selectedUsers.find((user: any) => user._id == practice?.mainUserId);
          let port = [];
          let clientUserCount = 0;
        const companies = selectedCompanies.filter((company: any) => company.organizationId == practice._id && (company.type == "QBO" || company.type == "Xero")).map((comp: any)=>{
          
          if(comp.addOn){
            port = comp.addOn.filter((addon: any) => {
              if(addon.appId == "6094f291bec72a1e8f69cc09" && addon.isActive == true){
                return addon
              }
            })
          }
          if(comp.client_users?.length > 0){
            clientUserCount = clientUserCount + 1;
          }
              return {
                  "_id":comp._id,
                  "date_added": moment(comp.date_added).format(),
                  "type":comp.type,
                  "lastVisited":comp.lastVisited
              }
          });
          let portCount = port.length;
        const delCompanies = selectedDeletedCompanies.filter((company: any) => company.organizationId == practice._id).map((comp: any)=>{
            return {
                "_id":comp._id,
                "date_added": moment(comp.date_added).format(),
                "date_deleted": moment(comp.date_deleted).format(),
                "type":comp.type
            }
        });
  
        const pracUsers = selectedUsers.filter((user: any) => {
          if(practice.users.includes(user._id)){
            return user
          }
        });
  
        const pracPromo = selectedPromos.filter((pro: any) => pro.promoCode == practice.promoCode)
        const invoices = selectedInvoices.filter((invoice: any) => invoice.organizationId == practice._id).map((invoice: any)=>{
          
            return {
                "invStatus":invoice.status,
                "invAmount": invoice.billed,
                "invClients": invoice.noOfSubcriptions,
                "invDate": moment(invoice.billDate).format("MM/DD/YYYY"),
                "exchangeRate": invoice.exchangeRate ? invoice.exchangeRate : 1,
                "unitPrice": invoice.unitPrice?.unitPrice,
                "promoCode": invoice.promoCode ? invoice.promoCode.promoCode : ''
            }
        });
        const promoTotal = invoices.filter((invs: any) => invs.promoCode == practice.promoCode).length;
        
        let promoCode = practice.promoCode;
        if(pracPromo && promoTotal >= pracPromo[0]?.months)
        {
          promoCode = '';
        }
          const mainUsername = user ? (user.firstname + ' ' + user.lastname) : '';
          const mainUseremail = user ? user.email : '';
          let basePrice = practice.currency == "USD" ? practice.plan[0]?.priceUSD : practice.plan[0]?.priceCAD;
          let pricing = basePrice ? basePrice : 0;
          let promoType = "";
          let mrr = companies.length * pricing;
          if(promoCode && promoCode != ""){
            promoType = pracPromo[0]?.promoType;
            if(pracPromo && pracPromo[0]?.promoType == "Fixed"){
                  pricing = pracPromo[0].promoPrice;
                  mrr = pracPromo[0].promoPrice
            }else{
              
              if(pracPromo[0].promoType == "Discount"){
                let newBasePrice = (basePrice * pracPromo[0].promoPrice) / 100;
                pricing = basePrice - newBasePrice;
              }
              if(pracPromo[0].promoType == "Flat"){
                pricing = pracPromo[0].promoPrice;
              }
              mrr = companies.length * pricing;
            }
            
          }


          let date_added = moment(practice.date_added).format("MM/DD/YYYY");
        let companiesCount = companies.length ? companies.length : 0;
        let usersCount = pracUsers.length ? pracUsers.length : 0;
        const statDate = moment(practice.end_date);
        let status = "";
          if (practice.isCancelled && practice.isCancelled == true) {
            status = 'Canceled';
          } else if (practice.isBilling && practice.isBilling == true) {
            status = 'Paid';
          } else {
            if(moment().diff(statDate, "days") >= 0){
            status = 'Trial Expired';
            }else{
              status = 'Trial';
            }
            
        }

        let statusDate = '';
        let isoDate = new Date(practice.end_date);
        if (practice.isCancelled && practice.isCancelled == true) {
          let canDate = practice.cancelledDate ? practice.cancelledDate : practice.end_date;
          isoDate = new Date(canDate);
        }
        statusDate = moment(isoDate).format("MM/DD/YYYY");

        let isActive = false;
        let isIdle = false;
        if (practice.isBilling && practice.isBilling == true && !practice.isCancelled) {
          let totalUsers = pracUsers?.length;
          
          let more30Users = pracUsers?.filter((user: any)=>{
            let inputMoment = moment(user.lastLogin, "YYYY-MM-DD");
            let currentMoment = moment();
            let daysDifference = currentMoment.diff(inputMoment, 'days');
            if(daysDifference >30){
              return user;
            }
          });
          
          let less15Users = pracUsers?.filter((user: any)=>{
            let inputMoment = moment(user.lastLogin, "YYYY-MM-DD");
            let currentMoment = moment();
            let daysDifference = currentMoment.diff(inputMoment, 'days');
            if(daysDifference >= 0 && daysDifference <= 15){
              return user;
            }
          });
          let less1630Users = pracUsers?.filter((user: any)=>{
            let inputMoment = moment(user.lastLogin, "YYYY-MM-DD");
            let currentMoment = moment();
            let daysDifference = currentMoment.diff(inputMoment, 'days');
            if(daysDifference >= 16 && daysDifference <= 30){
              return user;
            }
          });
          
          let totalClients = companies?.length;
          let more30Clients = companies?.filter((user: any)=>{
            let inputMoment = moment(user.lastVisited, "YYYY-MM-DD");
            let currentMoment = moment();
            let daysDifference = currentMoment.diff(inputMoment, 'days');
            if(daysDifference >30){
              return user;
            }
          });
          let less15Clients = companies?.filter((user: any)=>{
            let inputMoment = moment(user.lastVisited, "YYYY-MM-DD");
            let currentMoment = moment();
            let daysDifference = currentMoment.diff(inputMoment, 'days');
            if(daysDifference >= 0 && daysDifference <= 15){
              return user;
            }
          });
          let less1630Clients = companies?.filter((user: any)=>{
            let inputMoment = moment(user.lastVisited, "YYYY-MM-DD");
            let currentMoment = moment();
            let daysDifference = currentMoment.diff(inputMoment, 'days');
            if(daysDifference >= 16 && daysDifference <= 30){
              return user;
            }
          });
          let redUserPercent = (more30Users.length / totalUsers) * 100;
          let redClientPercent = (more30Clients.length / totalClients) * 100;
  
          let combinedUsers = less15Users.length + less1630Users.length;
          let combinedClients = less15Clients.length + less1630Clients.length;
  
          let greenClientPercent = (combinedClients / totalClients) * 100;
          let greenUserPercent = (combinedUsers / totalUsers) * 100;
  
          if (practice.isBilling && practice.isBilling == true) {
            // console.log(practice.organizationname, greenClientPercent, greenUserPercent);
            if(greenClientPercent >= 75 && greenUserPercent >= 75){
              isActive = true;
            }
            // console.log(redClientPercent,redUserPercent);
            if(redClientPercent >= 20 || redUserPercent >= 35){
              isIdle = true;
            }
          }  
        }
        
        let id = index + 1 ;
          return {
            ...practice,
            promoCode,
            mainUsername,
            mainUseremail,
            companies,
            invoices,
            pracPromo,
            delCompanies,
            pracUsers,
            pricing,
            promoType,
            mrr,
            date_added,
            companiesCount,
            usersCount,
            status,
            statusDate,
            isIdle,
            isActive,
            portCount,
            clientUserCount,
            id
          };
        });
        return patchState(store, {dashboardDetails : getUpdatePractices});
    }
    },
    setFilterId: function(id: any){
      patchState(store, {filterOrgId: id});
    },
    resetAccountState() {
      return patchState(store, initialCommonState);
    },
  }))
);
